
import React from 'react'
import classes from '../styles/footer.module.sass'


const Footer = () => {
    

    return (
        <div className={classes.footer}>
            <div className={classes.footercontent}>
                <div className={classes.footertext}>
                    <a className={classes.footertext} href="https://liu.se/">Linköpings universitet</a><br/>
                    <a className={classes.footertext} href="https://www.ida.liu.se/">Institutionen för datavetenskap</a><br/>
		            <a className={classes.footertext} href="mailto:ai-for-naturligt-sprak@ida.liu.se">ai-for-naturligt-sprak@ida.liu.se</a>
                </div>
                <div className={classes.footerpart}>
                    <p className={classes.footertext}>
                    Denna webbsajt innehåller kursmaterialet för kursen ETE335 AI för naturligt språk.<br/>
                    Materialet är licenserat under en <a href="creativecommons.org/licenses/by/4.0/">Creative Commons Erkännande 4.0 Internationell licens</a>.<br/>
                    Copyright © 2022, Marco Kuhlmann &amp; Oskar Holmström
                    </p>
		        </div>
              
            </div>
           
        </div>

    )
}

export default Footer
