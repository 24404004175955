import React from "react";

import { useMsal } from '../utility/auth/msalContext';
import { loginRequest } from "./../utility/auth/msalConfig";
import classes from '../styles/login.module.sass'

function Login() {
  const { loading, isAuthenticated, login, logout, token } = useMsal();

  if (loading){
    return <div>Laddar ...</div>
  }

  return (    
    <div>
      {(!isAuthenticated || !token) && (
        <button className={classes.button} small color="primary" onClick={() => login(loginRequest, "loginRedirect")}>Logga in</button>
      )}
      {isAuthenticated && token && (
        <div className={classes.logoutcontainer}>
          {/*<h3 className={classes.name}>{user.name}</h3>*/}
          <button className={classes.button} small color="primary" onClick={() => logout()}>Logga ut</button>
          
        </div>
      )}
                
    </div>
  );
}

export default Login;